import sitesHandler from "../../services/Sites";

export default {
    async load({commit, dispatch}, payload) {
        try {
            let {data: {data}} = await sitesHandler.all(payload);
            data.data = data.data.map((item) => {
                return {
                    ...item,
                    nichecategories: item.nichecategories.map((cat) => ({id: cat.nichecategory.id, name: cat.nichecategory.name}))
                }
            })
            commit('SET_SITES', data);
        } catch (error) {
            commit('SET_SITES', []);
        }
    },
    async store({commit, dispatch}, payload) {
        return sitesHandler.store(payload);
    },
    async update({commit, dispatch}, payload) {
        return sitesHandler.update(payload);
    },
    async remove({commit, dispatch}, payload) {
        return sitesHandler.remove(payload);
    },
    async destroy({commit, dispatch}, payload) {
        return sitesHandler.destroy(payload);
    },
    async restore({commit, dispatch}, payload) {
        return sitesHandler.restore(payload);
    },
}
